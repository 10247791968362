// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-blog-post-js": () => import("./../../../src/components/blog-post.js" /* webpackChunkName: "component---src-components-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-book-js": () => import("./../../../src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-econ-dl-js": () => import("./../../../src/pages/econ-dl.js" /* webpackChunkName: "component---src-pages-econ-dl-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-us-js": () => import("./../../../src/pages/join-us.js" /* webpackChunkName: "component---src-pages-join-us-js" */),
  "component---src-pages-journal-js": () => import("./../../../src/pages/journal.js" /* webpackChunkName: "component---src-pages-journal-js" */),
  "component---src-pages-manifesto-js": () => import("./../../../src/pages/manifesto.js" /* webpackChunkName: "component---src-pages-manifesto-js" */),
  "component---src-pages-modern-slavery-statement-mdx": () => import("./../../../src/pages/modern-slavery-statement.mdx" /* webpackChunkName: "component---src-pages-modern-slavery-statement-mdx" */),
  "component---src-pages-privacy-mdx": () => import("./../../../src/pages/privacy.mdx" /* webpackChunkName: "component---src-pages-privacy-mdx" */),
  "component---src-pages-services-design-services-js": () => import("./../../../src/pages/services/design-services.js" /* webpackChunkName: "component---src-pages-services-design-services-js" */),
  "component---src-pages-services-empower-your-team-js": () => import("./../../../src/pages/services/empower-your-team.js" /* webpackChunkName: "component---src-pages-services-empower-your-team-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-shape-mindsets-js": () => import("./../../../src/pages/services/shape-mindsets.js" /* webpackChunkName: "component---src-pages-services-shape-mindsets-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */)
}

